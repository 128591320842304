<template>
  <b-row>
   <!-- Da nascondere per la demo -->
    <!-- <b-col align="center">
      <b-button-group>
        <b-button variant="link" class="text-decoration-none">
          <b-icon icon="list-stars"></b-icon> Polizze
        </b-button>
        <b-button variant="link" class="text-decoration-none">
          <b-icon icon="pencil-square"></b-icon> Modifica Dati
        </b-button>
        <b-button variant="link" class="text-decoration-none">
          <b-icon icon="arrow-repeat"></b-icon> Sostituisci polizza
        </b-button>
        <b-button variant="link" class="text-decoration-none">
          <b-icon icon="arrow-return-right"></b-icon> Voltura Contratto
        </b-button>
        <b-button variant="link" class="text-decoration-none">
          <b-icon icon="cash-stack"></b-icon> Incassa Titoli
        </b-button>
        <b-button variant="link" class="text-decoration-none">
          <b-icon icon="x-circle"></b-icon> Sinistro
        </b-button>
      </b-button-group>
    </b-col> -->
  </b-row>
</template>

<script>
export default {};
</script>
<style scoped>
  .btn-group, .btn-group-vertical {
      margin-top: 4px; 
      margin-bottom: 10px
  }
</style>
