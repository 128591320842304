<template>
  <b-form-input
    size="sm"
    type="text"
    placeholder="Pagina"
    @keydown.enter.prevent="onInput"
    :formatter="formatter"
    :readonly="readonly"
  ></b-form-input>
</template>

<script>
export default {
  methods: {
    onInput(event) {
      this.$emit("pagination", event.target.value);
    },
    formatter(value) {
      return isNaN(value) || !parseInt(value) ? "" : value;
    },
  },
  props: {
    value: null,
    readonly: {
      value: Boolean,
      default: false,
    },
  },
};
</script>
