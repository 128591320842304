<template>
  <div class="mx-1">
    <table-group
      :filterOn="{ byAttribute: {id: this.resourceId} }"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      noActions
      noCustom
      noInnerWidth
    ></table-group>
  </div>
</template>

<script>

// import { mapActions } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
export default {
  name:"General",
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "registry_group", 
      resource: "registry_groups", 
      filterName: "filterRegistryGroupDetail",
      filter: this.initFilter(),
      tableRef: "RegistryGroupDetailTableRef",
      fields: [
        {
          key: "code",
          label: "Codice",
          sortable: true,
          sortKey: "byAttribute.code",
        },
        {
          key: "title",
          label: "Titolo",
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "anagrafica",
          label: "Anagrafica",
          sortable: false,
        }
      ],
    };
  },
  components: {
   TableGroup: () => import('@/components/tables/RegistryGroups')
  },
  methods: {
    initFilter() {
      let init = {
        code: null,
        title: null
      };

      return init;
    },
    // ...mapActions("filters", { saveFilterByName: "saveByName", removeFilterByName: "removeByName"}),
  },
  props: {
    resourceId: Number
  },
};
</script>
